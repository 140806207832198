.App {
  text-align: center;
  /* background-color: #42465a; */
  margin: 0;
  min-height: 100vh;
  /* color: white; */
  color: #333333;
  font-family: "EB Garamond", Sans-serif;
  font-weight: 400;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.page {
  padding: 5px;
}

.sign-in-page {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.loading {
  display: -webkit-flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  padding: 40px 55px 45px 55px;
  border-radius: 15px;
}

.home {
  display: -webkit-flex;
  align-items: center;
  height: 300px;
  justify-content: center;
}

.microsoft-logo {
  margin: 15px;
  padding: 30px;
}

.sign-in {
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  height: 320px;
  width: 500px;
  flex-direction: column;
  box-shadow: 0px 14px 80px rgba(1, 1, 1, 0.2);
  padding: 40px 55px 45px 55px;
  background-color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -160px; /* this is half the height of your div*/
  margin-left: -250px; /*this is half of width of your div*/
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
}

.bundle-list {
  text-decoration: none;
  list-style-type: none;
  padding: 15px;
  margin: 30px;
  display: flex;
  flex-direction: column;
  border-radius: 30px;
  border-color: #282c34;
  border-style: solid;
  justify-content: space-evenly;
  flex: 2;

  margin-right: 10vw;
  margin-left: 10vw;
}

.App-link {
  color: #61dafb;
}

.error {
  padding: 40px 55px 45px 55px;
}

.timeout {
  display: -webkit-flex;
  align-items: center;
  flex-direction: column;
}

.pointer {
  cursor: pointer;
}

.pagination {
  padding: 0.5rem;
  display: flex;
  justify-content: space-between;
}

.footer {
  margin-top: auto;
  width: 100%;
  background-color: black;
}

.negative {
  background-color: rgba(245, 30, 30, 0.192);
}

.positive {
  background-color: rgba(7, 230, 7, 0.123);
}
